import React, { Component } from "react"
import styled from "styled-components"
import { colors, siteVars } from "../components/siteVars"
import Excercise from "../components/excercise"
import { Link } from "gatsby"
import Cross from "../components/cross"
import Contentbox from "./contentbox"
import Button from "./button"

const StyledProgramNew = styled.div`
  position: relative;
  .back-arrow {
    transform: rotateZ(-90deg);
    filter: invert(1);
  }

  .program-minimized {
    display: flex;
    justify-content: space-between;
    transition: 200ms ease-in-out;
    h3 {
      margin: 0;
    }
    .program-minimized-left {
      display: flex;
      .title-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      p {
        margin: 0;
      }

      .arrow-down-button-program {
        ${siteVars.buttonReset}
        border: none;
        transition: 200ms ease-in-out;
        img {
          width: 20px;
          object-fit: contain;
          margin-right: 1rem;
        }
      }
    }
    .program-minimized-button-container {
      display: flex;
      align-items: center;
      position: absolute;
      right: 1rem;
      top: 0;
      bottom: 0;
      transition: 200ms ease-in-out;
      .delete-button-container {
        display: flex;
        .confirm-delete-button {
          top: 0;
          left: 100%;
          z-index: 1;
          max-width: 0;
          overflow: hidden;
          padding: 0;
          border: none;
          margin: 0;
          pointer-events: none;
          img {
            filter: brightness(0);
          }
          &.active {
            pointer-events: initial;
            max-width: 10rem;
            padding: 0.8rem;
            margin-left: 0.5rem;
          }
        }
      }
      button {
        &.button-neutral {
          margin-right: 0.5rem;
        }
        &.button-green {
          margin-right: 0.5rem;
        }
        &.button-warning {
          &.active {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .program-expanded {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    transition: 200ms ease-in-out;
  }

  &.active {
    .arrow-down-button-program {
      transform: rotateX(180deg);
    }
    .program-minimized {
      opacity: 0.5;
    }
    .program-expanded {
      max-height: 200vh;
      overflow: scroll;
    }
    .program-minimized-button-container {
      opacity: 0;
      pointer-events: none;
    }
  }
  .program-minimized-expand-button {
    display: none;
  }
  @media (max-width: 768px) {
    .program-minimized {
      .program-minimized-button-container {
        /* display: none; */
        max-width: 0;
        position: absolute;
        overflow: hidden;
        right: 40px;
        transition: 200ms ease-in-out;
      }
      .program-minimized-expand-button {
        display: flex;
        height: 100%;
        align-items: center;
        ${siteVars.buttonReset}
        border: none;
        &.cross {
          display: none;
        }
        &.three-dots {
          display: flex;
        }
      }
      &.mobile-active {
        position: relative;
        .program-minimized-button-container {
          /* display: flex; */
          max-width: 100%;
        }
        .program-minimized-expand-button {
          &.cross {
            display: inline;
          }
          &.three-dots {
            display: none;
          }
        }
        .title-section {
          opacity: 0.2;
        }
      }
    }
  }
`

export default class ProgramNew extends Component {
  state = {
    edit: false,
  }
  expandProgram = () => {
    const allExcerciseContainers = document.querySelectorAll(
      ".program-component"
    )
    allExcerciseContainers.forEach(container => {
      if (
        container.id ===
        `programComponentId${this.props.program._id + this.props.toggleKey}`
      ) {
        container.classList.toggle("active")
      } else {
        container.classList.remove("active")
      }
    })
  }
  areYouSure = () => {
    this.setState({
      edit: !this.state.edit,
    })
  }

  toggleMobileButtonContainer = excerciseIndex => {
    const allExcercises = document.querySelectorAll(".program-minimized")

    allExcercises.forEach((excercise, index) => {
      if (index === excerciseIndex) {
        excercise.classList.toggle("mobile-active")
      } else {
        excercise.classList.remove("mobile-active")
      }
    })
  }

  render() {
    let buttonSection = <div></div>
    let permissions = this.props.permissions
    if (this.props.coachOwned) {
      permissions = "admin"
    }
    switch (permissions) {
      case "user":
        buttonSection = (
          <Link to={`/view-program/${this.props.program._id}`}>
            <Button className="button-green" circle invert>
              <img
                src="/images/share.png"
                alt="share"
                width="15px"
                height="15px"
              />
            </Button>
          </Link>
        )
        break
      case "proUser":
        buttonSection = (
          <Link to={`/view-program/${this.props.program._id}`}>
            <Button className="button-green" circle invert>
              <img
                src="/images/share.png"
                alt="share"
                width="15px"
                height="15px"
              />
            </Button>
          </Link>
        )
        break
      case "coach":
        buttonSection = (
          <Link to={`/view-program/${this.props.program._id}`}>
            <Button className="button-green" circle invert>
              <img
                src="/images/share.png"
                alt="share"
                width="15px"
                height="15px"
              />
            </Button>
          </Link>
        )
        break
      case "admin":
        buttonSection = (
          <>
            <Link to={`/view-program/${this.props.program._id}`}>
              <Button className="button-green" circle invert>
                <img
                  src="/images/share.png"
                  alt="share"
                  width="15px"
                  height="15px"
                />
              </Button>
            </Link>
            <Button
              circle
              invert
              className="button-neutral"
              onClick={() => {
                this.props.editInit(this.props.program)
              }}
            >
              <img
                src="/images/edit.png"
                alt="share"
                width="15px"
                height="15px"
              />
            </Button>

            <div
              className="delete-button-container"
              id={`deleteButtonContainer${
                this.props.program._id + this.props.toggleKey
              }`}
            >
              <Button
                color={this.state.edit ? "green" : "red"}
                circle
                className="button-warning"
                onClick={e => {
                  this.areYouSure(this.props.index)
                }}
              >
                {this.state.edit ? (
                  <img
                    className="back-arrow"
                    src="/images/arrow-down.png"
                    alt="back"
                    width="15px"
                  />
                ) : (
                  <Cross color={colors.white} />
                )}
              </Button>
              <Button
                circle
                invert
                color="warning"
                className={`confirm-delete-button ${
                  this.state.edit ? "active" : ""
                }`}
                onClick={() => {
                  this.props.deleteProgram(this.props.program._id)
                }}
              >
                <img src="/images/trash.png" alt="trash" width="15px" />
              </Button>
            </div>
          </>
        )
        break

      default:
        break
    }

    // const buttonSectionOld = (
    //   <>
    //     <div className="program-minimized-outer-button-container">
    //       <div className="program-minimized-button-container">
    //         <Link to={`/view-program/${this.props.program._id}`}>
    //           <Button className="button-green" circle invert>
    //             <img
    //               src="/images/share.png"
    //               alt="share"
    //               width="15px"
    //               height="15px"
    //             />
    //           </Button>
    //         </Link>
    //         {!this.props.coach || this.props.coachOwned ? (
    //           <>
    //             <Button
    //               circle
    //               invert
    //               className="button-neutral"
    //               onClick={() => {
    //                 this.props.editInit(this.props.program)
    //               }}
    //             >
    //               <img
    //                 src="/images/edit.png"
    //                 alt="share"
    //                 width="15px"
    //                 height="15px"
    //               />
    //             </Button>

    //             <div
    //               className="delete-button-container"
    //               id={`deleteButtonContainer${
    //                 this.props.program._id + this.props.toggleKey
    //               }`}
    //             >
    //               <Button
    //                 color={this.state.edit ? "green" : "red"}
    //                 circle
    //                 className="button-warning"
    //                 onClick={e => {
    //                   this.areYouSure(this.props.index)
    //                 }}
    //               >
    //                 {this.state.edit ? (
    //                   <img
    //                     className="back-arrow"
    //                     src="/images/arrow-down.png"
    //                     alt="back"
    //                     width="15px"
    //                   />
    //                 ) : (
    //                   <Cross color={colors.white} />
    //                 )}
    //               </Button>
    //               <Button
    //                 circle
    //                 invert
    //                 color="warning"
    //                 className={`confirm-delete-button ${
    //                   this.state.edit ? "active" : ""
    //                 }`}
    //                 onClick={() => {
    //                   this.props.deleteProgram(this.props.program._id)
    //                 }}
    //               >
    //                 <img src="/images/trash.png" alt="trash" width="15px" />
    //               </Button>
    //             </div>
    //           </>
    //         ) : null}
    //       </div>
    //     </div>
    //     <button
    //       className="program-minimized-expand-button three-dots"
    //       onClick={() => {
    //         this.toggleMobileButtonContainer(this.props.index)
    //       }}
    //     >
    //       <ThreeDots color={colors.darkGrey} />
    //     </button>
    //     <button
    //       className="program-minimized-expand-button cross"
    //       onClick={() => {
    //         this.toggleMobileButtonContainer(this.props.index)
    //       }}
    //     >
    //       <Cross color={colors.darkGrey} />
    //     </button>
    //   </>
    // )

    return (
      <StyledProgramNew
        className="program-component"
        id={`programComponentId${
          this.props.program._id + this.props.toggleKey
        }`}
      >
        <Contentbox>
          <div className="program-minimized">
            <div className="program-minimized-left">
              <button
                className="arrow-down-button-program"
                onClick={() => {
                  this.expandProgram()
                }}
              >
                <img src={`/images/arrow-down.png`} alt="arrow-down" />
              </button>
              <div className="title-section">
                <h3>{this.props.program.title}</h3>
                <p>Author: {this.props.program.username}</p>
                {/* {this.props.all ? <p>Owner: {this.findUsername()}</p> : null} */}
              </div>
            </div>
            <div className="program-minimized-outer-button-container">
              <div className="program-minimized-button-container">
                {buttonSection}
              </div>
            </div>
          </div>
          <div className="program-expanded">
            <div className="excercise-list-container">
              <h3>{this.props.program.title}</h3>
              {this.props.program.program.map((excercise, index) => (
                <Excercise
                  insetShadow
                  key={excercise._id + index}
                  excercise={excercise}
                  noControls
                />
              ))}
            </div>
          </div>
        </Contentbox>
      </StyledProgramNew>
    )
  }
}
