import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import { db } from "../siteVars"
import popupAlert from "../popup-alert"
import ProgramListNew from "../program-list-new"
import { getUser } from "../handle-auth"
import Searchbar from "../searchbar"
import Contentbox from "../contentbox"

const StyledProgramsUser = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  align-items: flex-start;

  h1 {
    margin-top: 0;
  }
  .fullwidth {
    grid-column: 1 / 3;
  }
  .excercise-list-container {
    .excercise-component {
      position: relative;
    }
  }

  .edit-abort-button {
    margin-right: 0.5rem;
  }

  .loader-container {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .program-headers {
    display: flex;
    justify-content: flex-end;
    margin-right: 6.5rem;

    p {
      margin-left: 2rem;
      font-weight: bold;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .program-headers {
      margin-right: 9rem;
      opacity: 0;
      transition: 200ms ease-in-out;
      &.mobile-active {
        opacity: 1;
      }
    }
  }
`

export default class ProgramsUser extends Component {
  state = {
    excercises: [],
    program: [],
    programTitle: "",
    loading: true,
    programs: [],
    privatePrograms: [],

    private: true,
    _id: "",
    programId: "",
    edit: false,
    filtered1: [],
    filtered2: [],
    filtered3: [],
    filtered4: [],
  }

  componentDidMount() {
    this.fetchExcercises()
    this.fetchPrograms()

    this.getUserId()
  }

  getUserId = async () => {
    const res = await getUser()
    this.setState({
      _id: res._id,
    })
    this.fetchPrivatePrograms(res._id)
  }

  fetchExcercises = () => {
    this.setState({
      loading: true,
    })
    axios.get(db.lambda + "excercises/").then(res => {
      this.setState({
        excercises: res.data,
        loading: false,
      })
    })
  }

  addToProgram = exc => {
    let excercise = { ...exc }
    let tempProgram = this.state.program
    excercise.set = 0
    excercise.rep = 0
    tempProgram.push(excercise)
    this.setState({
      program: tempProgram,
    })
  }

  submitProgram = () => {
    const newProgram = {
      title: this.state.programTitle,
      program: this.state.program.map(program => {
        return {
          rep: program.rep,
          set: program.set,
          title: program.title,
          description: program.description,
          difficulty: program.difficulty,
          image: program.image,
          video: program.video,
          instaVideo: program.instaVideo,
          comment: program.comment,
          topCat: program.topCat,
          middleCat: program.middleCat,
          bottomCat: program.bottomCat,
        }
      }),
      owner: this.state._id,
      private: this.state.private,
    }
    console.log(newProgram)
    axios

      .post(db.lambda + "programs/add/", newProgram)
      .then(res => {
        console.log(res.data)
        popupAlert("Programmet har sparats!")
        this.reloadPrograms()
      })
      .catch(err => console.log("Error: " + err.response.data))
  }

  removeExcercise = index => {
    let tempProgram = [...this.state.program]
    tempProgram.splice(index, 1)
    this.setState({
      program: tempProgram,
    })
  }

  fetchPrograms = () => {
    this.setState({
      loading: true,
    })

    axios
      .get(db.lambda + "programs/public")
      .then(res => {
        this.setState({
          programs: res.data,
          loading: false,
        })
      })
      .catch(err => console.log("Error: " + err))
  }

  fetchPrivatePrograms = id => {
    axios
      .get(db.lambda + "programs/private/" + id)
      .then(res => {
        this.setState({
          privatePrograms: res.data,
          loading: false,
        })
      })
      .catch(err => console.log("Error: " + err))
  }

  reloadPrograms = () => {
    this.fetchPrograms()
    this.getUserId()
  }

  updateRepSet = (type, dir, index) => {
    let tempProgram = [...this.state.program]
    if (type === "rep") {
      if (dir === "inc") tempProgram[index].rep++
      if (dir === "dec" && tempProgram[index].rep !== 0)
        tempProgram[index].rep--
    }
    if (type === "set") {
      if (dir === "inc") tempProgram[index].set++
      if (dir === "dec" && tempProgram[index].set !== 0)
        tempProgram[index].set--
    }

    this.setState({
      program: tempProgram,
    })
  }
  updateRepSetInput = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    let tempProgram = [...this.state.program]

    if (e.target.name === "set" && e.target.value >= 0 && tempProgram[index]) {
      tempProgram[index].set = e.target.value
    } else if (e.target.name === "set") tempProgram[index].set = 0
    if (e.target.name === "rep" && e.target.value >= 0 && tempProgram[index]) {
      tempProgram[index].rep = e.target.value
    } else if (e.target.name === "rep") tempProgram[index].rep = 0

    console.log("go")
    setTimeout(() => {
      this.setState({
        program: tempProgram,
      })
    }, 50)
  }

  onChange = e => {
    if (e.target.name === "private") {
      // this.setState({
      //   private: e.target.checked,
      // })
      return
    }
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  onChangePrivate = () => {}

  changeComment = (e, index) => {
    let tempProgram = [...this.state.program]
    tempProgram[index].comment = e.target.value
    this.setState({
      program: tempProgram,
    })
  }

  editInit = program => {
    this.setState({
      edit: true,
      program: program.program,
      programTitle: program.title,
      programId: program._id,
      private: program.isPrivate,
    })
  }

  abortEdit = () => {
    this.setState({
      edit: false,
      program: [],
      programTitle: "",
      programId: "",
      private: true,
    })
  }

  submitEdit = () => {
    const editedProgram = {
      title: this.state.programTitle,
      program: this.state.program.map(program => {
        return {
          rep: program.rep,
          set: program.set,
          title: program.title,
          description: program.description,
          difficulty: program.difficulty,
          image: program.image,
          video: program.video,
          instaVideo: program.instaVideo,
          comment: program.comment,
          topCat: program.topCat,
          middleCat: program.middleCat,
          bottomCat: program.bottomCat,
        }
      }),
      owner: this.state._id,
      private: this.state.private,
    }
    axios

      .post(
        db.lambda + "programs/update/" + this.state.programId,
        editedProgram
      )
      .then(res => {
        console.log(res.data)
        popupAlert("Program updated!")
        this.abortEdit()
        this.reloadPrograms()
      })
      .catch(err => console.log("Error: " + err.response.data))
  }

  returnFunction1 = array => {
    this.setState({
      filtered1: array,
    })
  }
  returnFunction2 = array => {
    this.setState({
      filtered2: array,
    })
  }
  returnFunction3 = array => {
    this.setState({
      filtered3: array,
    })
  }

  render() {
    return (
      <>
        <StyledProgramsUser>
          <div>
            <Contentbox>
              <h1>Programs</h1>
              {this.state.programs.length > 0 ? (
                <Searchbar
                  array={this.state.programs}
                  returnFunction={this.returnFunction2}
                  context="programs"
                />
              ) : null}
            </Contentbox>
            <ProgramListNew
              fetchPrograms={this.reloadPrograms}
              toggleKey="1"
              regular
              permissions="user"
              loading={this.state.loading}
              programs={this.state.filtered2}
              editInit={this.editInit}
            />
          </div>
        </StyledProgramsUser>
      </>
    )
  }
}
