import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import Excercise from "../excercise"
import { colors, siteVars, db } from "../siteVars"
import popupAlert from "../popup-alert"
import ProgramListNew from "../program-list-new"
import Loader from "../loader"
import { getUser, isWindow } from "../handle-auth"
import Searchbar from "../searchbar"
import Contentbox from "../contentbox"
import Cross from "../cross"
import Button from "../button"
import Input from "../input"
import CheckboxLock from "../checkbox-lock"

const StyledProgramsAdmin = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  align-items: flex-start;

  h1 {
    margin-top: 0;
  }
  .fullwidth {
    grid-column: 1 / 3;
  }
  .excercise-list-container {
    .excercise-component {
      position: relative;
      /* margin-bottom: 1rem; */
      /* padding-bottom: 0.3rem; */
      /* &:after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: ${colors.mediumGrey};
      } */
    }
  }
  /* .program-submit-button {
    ${siteVars.buttonReset}
    ${siteVars.buttonGreen}
  } */
  .edit-abort-button {
    /* ${siteVars.buttonReset}
    ${siteVars.buttonNeutral} */
    margin-right: 0.5rem;
  }
  /* input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  .program-title-input {
    margin-right: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
  } */
  .loader-container {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .program-headers {
    display: flex;
    justify-content: flex-end;
    margin-right: 6.5rem;

    p {
      margin-left: 2rem;
      font-weight: bold;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .program-headers {
      margin-right: 9rem;
      opacity: 0;
      transition: 200ms ease-in-out;
      &.mobile-active {
        opacity: 1;
      }
    }
  }
`

export default class ProgramsAdmin extends Component {
  state = {
    excercises: [],
    program: [],
    programTitle: "",
    loading: true,
    username: "",
    programs: [],
    privatePrograms: [],
    allPrograms: [],
    private: true,
    _id: "",
    programId: "",
    edit: false,
    filtered1: [],
    filtered2: [],
    filtered3: [],
    filtered4: [],
  }

  componentDidMount() {
    this.fetchExcercises()
    this.fetchPrograms()
    this.fetchAllPrograms()
    this.getUserId()
  }

  getUserId = async () => {
    const res = await getUser()
    this.setState({
      _id: res._id,
      username: res.username,
    })
    this.fetchPrivatePrograms(res._id)
  }

  fetchExcercises = () => {
    this.setState({
      loading: true,
    })
    axios.get(db.lambda + "excercises/").then(res => {
      this.setState({
        excercises: res.data,
        loading: false,
      })
    })
  }

  addToProgram = exc => {
    let excercise = { ...exc }
    let tempProgram = this.state.program
    excercise.set = 0
    excercise.rep = 0
    tempProgram.push(excercise)
    this.setState({
      program: tempProgram,
    })
  }

  submitProgram = () => {
    const newProgram = {
      title: this.state.programTitle,
      program: this.state.program.map(program => {
        return {
          rep: program.rep,
          set: program.set,
          title: program.title,
          description: program.description,
          difficulty: program.difficulty,
          image: program.image,
          video: program.video,
          instaVideo: program.instaVideo,
          comment: program.comment,
          topCat: program.topCat,
          middleCat: program.middleCat,
          bottomCat: program.bottomCat,
        }
      }),
      username: this.state.username,
      owner: this.state._id,
      private: this.state.private,
    }
    console.log(newProgram)
    axios

      .post(db.lambda + "programs/add/", newProgram)
      .then(res => {
        console.log(res.data)
        popupAlert("Programmet har sparats!")
        this.reloadPrograms()
      })
      .catch(err => console.log("Error: " + err.response.data))
  }

  removeExcercise = index => {
    let tempProgram = [...this.state.program]
    tempProgram.splice(index, 1)
    this.setState({
      program: tempProgram,
    })
  }

  fetchPrograms = () => {
    this.setState({
      loading: true,
    })

    axios
      .get(db.lambda + "programs/public")
      .then(res => {
        this.setState({
          programs: res.data,
          loading: false,
        })
      })
      .catch(err => console.log("Error: " + err))
  }

  fetchAllPrograms = () => {
    this.setState({
      loading: true,
    })

    axios
      .get(db.lambda + "programs/all")
      .then(res => {
        this.setState({
          allPrograms: res.data,
          loading: false,
        })
      })
      .catch(err => console.log("Error: " + err))
  }

  fetchPrivatePrograms = id => {
    axios
      .get(db.lambda + "programs/private/" + id)
      .then(res => {
        this.setState({
          privatePrograms: res.data,
          loading: false,
        })
      })
      .catch(err => console.log("Error: " + err))
  }

  reloadPrograms = () => {
    this.fetchPrograms()
    this.fetchAllPrograms()
    this.getUserId()
  }

  updateRepSet = (type, dir, index) => {
    let tempProgram = [...this.state.program]
    if (type === "rep") {
      if (dir === "inc") tempProgram[index].rep++
      if (dir === "dec" && tempProgram[index].rep !== 0)
        tempProgram[index].rep--
    }
    if (type === "set") {
      if (dir === "inc") tempProgram[index].set++
      if (dir === "dec" && tempProgram[index].set !== 0)
        tempProgram[index].set--
    }

    this.setState({
      program: tempProgram,
    })
  }
  updateRepSetInput = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    let tempProgram = [...this.state.program]

    if (e.target.name === "set" && e.target.value >= 0 && tempProgram[index]) {
      tempProgram[index].set = e.target.value
    } else if (e.target.name === "set") tempProgram[index].set = 0
    if (e.target.name === "rep" && e.target.value >= 0 && tempProgram[index]) {
      tempProgram[index].rep = e.target.value
    } else if (e.target.name === "rep") tempProgram[index].rep = 0

    console.log("go")
    setTimeout(() => {
      this.setState({
        program: tempProgram,
      })
    }, 50)
  }

  onChange = e => {
    if (e.target.name === "private") {
      this.setState({
        private: e.target.checked,
      })
      return
    }
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  changeComment = (e, index) => {
    let tempProgram = [...this.state.program]
    tempProgram[index].comment = e.target.value
    this.setState({
      program: tempProgram,
    })
  }

  editInit = program => {
    this.setState({
      edit: true,
      program: program.program,
      programTitle: program.title,
      programId: program._id,
      private: program.isPrivate,
    })
    if (isWindow) {
      window.scrollTo(0, 0)
    }
  }

  abortEdit = () => {
    this.setState({
      edit: false,
      program: [],
      programTitle: "",
      programId: "",
      private: true,
    })
  }

  submitEdit = () => {
    const editedProgram = {
      title: this.state.programTitle,
      program: this.state.program.map(program => {
        return {
          rep: program.rep,
          set: program.set,
          title: program.title,
          description: program.description,
          difficulty: program.difficulty,
          image: program.image,
          video: program.video,
          instaVideo: program.instaVideo,
          comment: program.comment,
          topCat: program.topCat,
          middleCat: program.middleCat,
          bottomCat: program.bottomCat,
        }
      }),
      owner: this.state._id,
      private: this.state.private,
    }
    axios

      .post(
        db.lambda + "programs/update/" + this.state.programId,
        editedProgram
      )
      .then(res => {
        console.log(res.data)
        popupAlert("Program updated!")
        this.abortEdit()
        this.reloadPrograms()
      })
      .catch(err => console.log("Error: " + err.response.data))
  }

  returnFunction1 = array => {
    this.setState({
      filtered1: array,
    })
  }
  returnFunction2 = array => {
    this.setState({
      filtered2: array,
    })
  }
  returnFunction3 = array => {
    this.setState({
      filtered3: array,
    })
  }
  returnFunction4 = array => {
    this.setState({
      filtered4: array,
    })
  }

  render() {
    return (
      <>
        <StyledProgramsAdmin>
          <Contentbox>
            <h1>{this.state.edit ? "Edit" : "Create"} Program</h1>
            {this.state.program.length > 0 ? (
              <>
                <div className="excercise-list-container">
                  {this.state.program.map((excercise, index) => (
                    <Excercise
                      insetShadow
                      altKey={excercise._id + index + "secondary"}
                      key={excercise._id + index + "secondary"}
                      excercise={excercise}
                      removeFromProgram={this.removeExcercise}
                      updateRepSet={this.updateRepSet}
                      updateRepSetInput={this.updateRepSetInput}
                      changeComment={this.changeComment}
                      rep={this.state.program[index].rep}
                      set={this.state.program[index].set}
                      index={index}
                      inProgram
                    />
                  ))}
                </div>
                <div style={{ height: "1rem" }}></div>
                <Input
                  label="Give your program a name"
                  type="text"
                  value={this.state.programTitle}
                  placeholder="Program name"
                  name="programTitle"
                  className="program-title-input"
                  onChange={this.onChange}
                />

                <CheckboxLock
                  label={`Program is ${
                    this.state.private ? "private" : "public"
                  }`}
                  type="checkbox"
                  name="private"
                  id="lockBox"
                  checked={this.state.private}
                  onChange={this.onChange}
                />
                {this.state.edit ? (
                  <>
                    <Button
                      circle
                      color="red"
                      className="edit-abort-button"
                      onClick={this.abortEdit}
                    >
                      <Cross color={colors.white} />
                    </Button>
                    <Button
                      color="green"
                      onClick={this.submitEdit}
                      className="program-submit-button"
                    >
                      Update
                    </Button>
                  </>
                ) : (
                  <Button
                    color="green"
                    onClick={this.submitProgram}
                    className="program-submit-button"
                  >
                    Create
                  </Button>
                )}
              </>
            ) : (
              <p>Add excercises to start</p>
            )}
          </Contentbox>
          <div>
            {this.state.loading ? (
              <div className="loader-container">
                <Loader dualRing color={colors.darkGrey} />
              </div>
            ) : (
              <>
                <Contentbox>
                  <h1>Excercises</h1>
                  {this.state.excercises.length > 0 ? (
                    <Searchbar
                      array={this.state.excercises}
                      returnFunction={this.returnFunction1}
                      context="excercises"
                    />
                  ) : null}
                </Contentbox>
                {this.state.filtered1.map((excercise, index) => (
                  <Excercise
                    altKey={excercise._id}
                    key={excercise._id}
                    index={index}
                    excercise={excercise}
                    programCandidate
                    addToProgram={this.addToProgram}
                  />
                ))}
              </>
            )}
          </div>
          <div>
            <Contentbox>
              <h1>Public programs</h1>
              {this.state.programs.length > 0 ? (
                <Searchbar
                  array={this.state.programs}
                  returnFunction={this.returnFunction2}
                  context="programs"
                  owner
                  _id={this.state._id}
                />
              ) : null}
            </Contentbox>
            <ProgramListNew
              permissions="admin"
              fetchPrograms={this.reloadPrograms}
              toggleKey="1"
              loading={this.state.loading}
              programs={this.state.filtered2}
              editInit={this.editInit}
            />
          </div>
          <div>
            <Contentbox>
              <h1>My private programs</h1>
              {this.state.privatePrograms.length > 0 ? (
                <Searchbar
                  array={this.state.privatePrograms}
                  returnFunction={this.returnFunction3}
                  context="programs"
                />
              ) : null}
            </Contentbox>
            <ProgramListNew
              permissions="admin"
              fetchPrograms={this.reloadPrograms}
              toggleKey="2"
              loading={this.state.loading}
              programs={this.state.filtered3}
              editInit={this.editInit}
            />
          </div>
          <div>
            <Contentbox>
              <h1>All programs</h1>
              {this.state.allPrograms.length > 0 ? (
                <Searchbar
                  array={this.state.allPrograms}
                  returnFunction={this.returnFunction4}
                  context="programs"
                />
              ) : null}
            </Contentbox>
            <ProgramListNew
              permissions="admin"
              fetchPrograms={this.reloadPrograms}
              toggleKey="3"
              all
              loading={this.state.loading}
              programs={this.state.filtered4}
              editInit={this.editInit}
            />
          </div>
        </StyledProgramsAdmin>
      </>
    )
  }
}
