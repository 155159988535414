import React, { Component, useContext } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { colors } from "../../components/siteVars"
import { getUser } from "../../components/handle-auth"
import Loader from "../../components/loader"
import ProgramsProUser from "../../components/page-components/programs-prouser"
import ProgramsCoach from "../../components/page-components/programs-coach"
import ProgramsAdmin from "../../components/page-components/programs-admin"
import ProgramsUser from "../../components/page-components/programs-user"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { navigate } from "gatsby"

const LoaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProgramsPage = () => {
  const state = useContext(GlobalStateContext)

  let content = (
    <LoaderContainer>
      <Loader dualRing color={colors.mediumGrey} />
    </LoaderContainer>
  )
  if (state.user.permissions) {
    if (state.user.permissions === "user") {
      content = <ProgramsUser />
    }
    if (state.user.permissions === "proUser") {
      content = <ProgramsProUser />
    }
    if (state.user.permissions === "coach") {
      content = <ProgramsCoach />
    }
    if (state.user.permissions === "admin") {
      content = <ProgramsAdmin />
    }
  } else {
    // content = navigate("/login")
  }
  return <Layout>{content}</Layout>
}

export default ProgramsPage
