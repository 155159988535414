import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import { colors, db } from "../components/siteVars"
import Loader from "../components/loader"
import { isWindow } from "./handle-auth"

import ProgramNew from "./program-new"

const StyledProgramList = styled.div`
  .loader-container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
  }
`

export default class ProgramListNew extends Component {
  state = {
    programs: [],
    loading: true,
    owners: [],
  }
  componentDidMount() {
    if (this.props.all) {
      this.getOwners()
    }
  }

  getOwners = () => {
    if (isWindow) {
      if (window.localStorage.getItem("authToken")) {
        axios
          .get(
            db.lambda +
              "users/getUsernames/" +
              window.localStorage.getItem("authToken")
          )
          .then(res => {
            this.setState({
              users: res.data,
            })
          })
          .catch(err => {
            console.log(err.response)
          })
      }
    }
  }

  deleteProgram = id => {
    axios
      .delete(db.lambda + "programs/" + id)
      .then(res => {
        console.log(res.data)
        setTimeout(() => {
          this.props.fetchPrograms()
        }, 100)
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <StyledProgramList>
        {this.props.loading ? (
          <div className="loader-container">
            <Loader dualRing color={colors.darkGrey} />
          </div>
        ) : (
          <div className="program-list-container">
            {this.props.programs.length > 0 ? null : <p>No programs</p>}
            {this.props.programs ? (
              <>
                {this.props.programs.map((program, index) => (
                  <ProgramNew
                    permissions={this.props.permissions}
                    toggleKey={this.props.toggleKey}
                    users={this.state.users}
                    all={this.props.all}
                    key={program._id}
                    coach={this.props.coach}
                    coachOwned={this.props._id === program.owner}
                    program={program}
                    deleteProgram={this.deleteProgram}
                    index={index}
                    regular={this.props.regular}
                    editInit={this.props.editInit}
                  />
                ))}
              </>
            ) : null}
          </div>
        )}
      </StyledProgramList>
    )
  }
}
